import {Component, TemplateRef} from '@angular/core';
import {NgIf, NgTemplateOutlet} from '@angular/common';
import {NgbToastModule} from '@ng-bootstrap/ng-bootstrap';
import {ToastService} from '../../services/toast.service';

@Component({
    selector: 'app-toasts',
    standalone: true,
    imports: [NgbToastModule, NgIf, NgTemplateOutlet],
    template: `
    @for (toast of toastService.toasts; track toast) {
      <ngb-toast
        [class]="toast.classname"
        [header]="toast.header"
        [autohide]="true"
        [delay]="toast.delay || 5000"
        (hidden)="toastService.remove(toast)"
      >
        <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
          <ng-template [ngTemplateOutlet]="toast.textOrTemplate"></ng-template>
        </ng-template>

        <ng-template #text>{{ toast.textOrTemplate }}</ng-template>
      </ngb-toast>
    }
  `,
    host: {class: 'toast-container position-fixed top-0 end-0 p-3', style: 'z-index: 1200'},
})
export class ToastsContainerComponent {
    constructor(public toastService: ToastService) {}

    isTemplate(toast: any) {
        return toast.textOrTematepl instanceof TemplateRef;
    }
}
